<script lang="ts" setup>
const { data: ongoingSale } = await usePromo().ongoingSale

const localePath = useLocalePath()

const now = ref(new Date())
useInterval(1000, {
  callback: () => {
    now.value = new Date()
  },
})

const timeRemaining = computed(() => {
  const end = ongoingSale.value.endAt
  const diff = end.getTime() - now.value.getTime()

  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
})
</script>

<template>
  <div>
    <div class="mb-3 flex items-center">
      <h2 class="text-center font-serif text-4xl capitalize">
        {{ $t('global.flash-sale') }}!
      </h2>
      <ClientOnly>
        <p
          class="ml-2 rounded-lg bg-orange-600 px-2 py-1 font-semibold text-white"
        >
          {{ timeRemaining }}
        </p>
      </ClientOnly>
    </div>
    <div class="flex gap-6 overflow-x-scroll md:grid md:grid-cols-3">
      <UIProductCard
        v-for="product in ongoingSale.groups[0].products"
        :key="product.id"
        :product="product"
        class="shrink-0 basis-[calc(100%-1.5rem)]"
      />
    </div>
    <div class="mt-2 text-center">
      <UButton
        :to="localePath('/flash-sale')"
        :label="$t('pages.home.more-flash-sale-products')"
        variant="link"
        color="indigo"
        size="lg"
        :padded="false"
        class="underline"
      />
    </div>
  </div>
</template>
